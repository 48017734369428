/* styles.css */
@keyframes wave {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.header-animation {
  position: relative;
  overflow: hidden;
}

.header-animation::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 166, 0, 0) 0%, rgba(255, 184, 0, 0.2) 50%, rgba(255, 184, 0, 0) 100%);
  background-size: 200% 100%;
  animation: wave 2s infinite;
}

textarea:focus,
input:focus {
  outline: none;
}