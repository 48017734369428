/* customScrollBars.css */

/* Style the scroll bar track */
.boxWithCustomScrollBars::-webkit-scrollbar {
  width: 6px;
  /* Set the width of the scroll bar */
}

/* Style the scroll bar thumb (the draggable part) */
.boxWithCustomScrollBars::-webkit-scrollbar-thumb {
  background-color: #0054FA;
  /* Set the color of the scroll thumb */
  border-radius: 4px;
  /* Set the border radius of the thumb */
}

.img-coming {
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 768px) {
  .img-coming {
    object-fit: cover;
  }
}