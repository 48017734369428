body {
  margin: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url('https://storage.googleapis.com/3d-container/letsApe/BG.png');
  background-size: cover;
  backdrop-filter: blur(4px);
  background-position: center;
  background-repeat: no-repeat;


}

.react-colorful__pointer {
  width: 14px !important;
  height: 14px !important;
}

@font-face {
  font-family: "F37-Regular";
  src: url("./assets/fonts/F37Judge-Regular.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.ttf");
}

:root {
  --font-f_m: "F37-Medium";
  --font-f_r: "F37-Regular";
  --font-i_r: "Inter-Regular";
}

.MuiDataGrid-virtualScroller {
  height: 46vh !important;
}

.slick-slidd {
  padding-block: 0px;

  .slick-slide {
    padding: 0px;
  }

  .slick-slide {
    padding: 0px;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #0054FA;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}