.logo {
    height: 300px;
}

.btn {
    width: 350px;
    cursor: pointer;
}

.apeChain {
    height: 200px;
    position: absolute;
    bottom: 0;
}

@media only screen and (max-width: 500px) {
    .logo {
        height: 250px;
    }

    .btn {
        width: 250px;
    }

    .apeChain {
        height: 160px;
    }
}