.typewriter {
    font-family: var(--font-f_r);
    white-space: pre-wrap;
    overflow: hidden;
    margin-top: 10px;
    padding-right: 4px;
    /* border-right: 4px solid #fff; */
    color: #fff;
    /* Cursor effect */
    /* animation: blinkCursor 0.7s steps(40) infinite; */
}

/* 
@keyframes blinkCursor {
    from {
        border-right-color: #fff;
    }

    to {
        border-right-color: transparent;
    }
} */